<template>
  <div class="layout-horizontal">
    <div class="layout-flexible">
      <consultancy style="height: 100%;" :account-id="innerAccountId" :organization-id="organizationId">

      </consultancy>
    </div>
    <div class="consultancy-list layout-inflexible layout-self-stretch padding">
      <ys-infinite-scroll-view style="height: 100%;">
        <div class="consultancy-list-item layout-horizontal layout-middle padding-vertical padding-horizontal-large"
             v-for="consultancy of this.consultancyList" :key="consultancy.id"
            @click="handleConsultancyClick(consultancy)">
          <el-badge class="layout-inflexible" value="新" :hidden="consultancy.unreadMessageCount == 0">
            <user-avatar :user="consultancy.account"></user-avatar>
          </el-badge>
          <div class="margin-left layout-flexible" style="overflow: hidden">
            <div>{{consultancy.account.nickName}}</div>
            <div v-if="consultancy.lastMessage" class="font-nowrap font-size-small font-color-placeholder">{{consultancy.lastMessage.shortMessage}}</div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import UserAvatar from "@/components/user/UserAvatar";
import Consultancy from "@/pages/consultancy/panel/Consultancy";

function doLoadConsultancyList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/consultancy/list/get/by_organization',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.consultancyList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doReloadConsultancyList() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/consultancy/list/get/by_organization',
    data: {
      organizationId: this.organizationId,
      pageNum: 1,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.consultancyList, res.data.list);
    this.totalPages = res.data.pages;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ConsultancyList",
  mixins: [httpapi],
  components: {Consultancy, UserAvatar, YsInfiniteScrollView},
  props: {
    accountId: Number,
    organizationId: Number,
    accountChanged: Function,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      consultancyList: [],
      pageNum: 1,
      totalPages: 0,

      innerAccountId: null,

      currentConsultancy: null,


    }
  },
  watch: {
    organizationId: {
      handler() {
        this.loadingCode ++;
        this.consultancyList = [];
        this.pageNum = 1;
        this.totalPages = 0;

        this.currentConsultancy = null;

        if(this.organizationId) {
          this.loadConsultancyList();
        }
      },
      immediate: true,
    },
    accountId: {
      handler() {
        this.innerAccountId = this.accountId;
      },
      immediate: true,
    }
  },
  methods: {
    loadConsultancyList(pageNum = 1) {
      doLoadConsultancyList.bind(this)(pageNum);
    },
    loadMore() {
      if(this.pageNum < this.totalPages) {
        this.loadConsultancyList(this.pageNum + 1);
      }
    },
    reloadConsultancyList() {
      doReloadConsultancyList.bind(this)();
    },
    handleConsultancyClick(consultancy) {
      this.innerAccountId = consultancy.account.id;
      this.reloadConsultancyList();
      this.$emit('update:account-id', this.innerAccountId);
      if(this.accountChanged) this.accountChanged(this.innerAccountId);
    }
  },
  activated() {
    this.reloadConsultancyList();
  },
  mounted() {
    window.eventBus.$on('notify:message', this.reloadConsultancyList);
  },
  destroyed() {
    window.eventBus.$off('notify:message', this.reloadConsultancyList);
  }
}
</script>

<style scoped>

.consultancy-list {
  width: 255px;
  background-color: white;
  box-sizing: border-box;
}

.consultancy-list-item {
  background-color: whitesmoke;
  border-radius: 8px;
  margin-bottom: 2.5px;
  cursor: pointer;
}

.consultancy-list-item:hover {
  background-color: #faf4f5;
}

</style>